@use "../common/color" as *;

.outer_noti {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.noti_image {
    object-fit: contain;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
}

.noti_desc {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        text-align: start;
        width: 100%;
    }

    .noti_title {
        color: $themecolor;
        font-weight: 600;
    }
}
