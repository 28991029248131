@use "../common/mixin" as *;
@use "../common/breakpoints" as *;
@use "../common/color" as *;

.error_page {
  @include flex-center;
  flex-direction: column;
  height: 100vh;

  &.morphisam {
    @include glassmorphism-effect;
    position: relative;
    border-radius: inherit;
  }

  .image_error {
    img {
      height: 400px;
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;

      @media #{$extra-small-device} {
        height: auto;
      }
    }
  }

  .title_error {
    h1 {
      @media #{$extra-small-device} {
        text-align: center;
        font-size: inherit;
        color: $mate-black;
      }
    }
  }

  .title_para {
    p {
      color: $mate-black;

      @media #{$extra-small-device} {
        text-align: center;
        font-size: inherit;
      }
    }
  }

  .error_button {
    a {
      @include flex-center;
    }
    i {
      font-size: 13px;
      padding-right: 5px;
    }
  }
}
