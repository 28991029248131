// themecolor
$themecolor: #ef5488;

$primarybtn: #ef5488;

$white: #fff;

$mate-black: #212121;

$black: #474646;

$gradient-one: #ef5488;

$gradient-two: #6c245a;

$footer-back-color:#000;

$header-top-color:#000;

$breadcrumbs-color:#000;
